<template>
  <div class="booked-awards-history-container awards-list-grid mt-2">
    <template v-if="historyList && historyList.length > 0">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          v-for="(item, index) in historyList"
          :key="index"
          class="product-col"
        >
          <awards-card
            :product="item.product"
            :item="item"
            @awardsCanceled="awardsCanceled"
          />

          <!-- <ProductCard
            v-if="item.product"
            class="awards-card"
            :product="item.product"
            linkComponentName="AwardsDetail"
          >
            <template v-slot:actions>

              <v-card-actions class="actions">
                <v-btn
                  v-if="
                    item?.product?.productClasses.some(
                      classItem => classItem.description === 'PRE_DI'
                    )
                  "
                  block
                  large
                  v-on:click.prevent="seeAward(item)"
                  @click.stop="dialog = true"
                  class="action-button elevation-0"
                  color="primary"
                  >{{ $t("awards.booked.seeCoupon") }}
                  <v-dialog
                    v-if="response"
                    scrollable
                    v-model="dialog"
                    width="500"
                    :fullscreen="$vuetify.breakpoint.mobile"
                  >
                    <v-card>

                      <v-img
                        height="200px"
                        contain
                        :src="getHandleImageSrc(item.product)"
                        onerror="this.onerror=null;this.src='/no-icon.png'"
                      ></v-img>

                      <v-card-text>
                        <div
                          class="voucher-detail"
                          v-html="response.messaggio_pv"
                        />
                        <barcode
                          v-bind:value="item.product.upc"
                          class="barcode"
                        />
                      </v-card-text>

                      <v-card-actions
                        class="voucher-close flex-column justify-end"
                      >
                        <v-btn
                          class="mb-3"
                          block
                          large
                          depressed
                          color="primary"
                          @click="printPremi"
                          >{{ $t("orders.button.print") }}</v-btn
                        >
                        <v-btn
                          block
                          large
                          color="primary"
                          outlined
                          @click="closeDialog"
                        >
                          {{ $t("common.close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-btn>



                <v-btn
                  v-else-if="
                    !item.logistic &&
                      !item.product.productClasses.some(
                        classItem => classItem.description === 'PRE_DI'
                      )
                  "
                  block
                  large
                  v-on:click.prevent="cancelAward(item)"
                  @click.stop="physicalDialog = true"
                  class="action-button elevation-0"
                  color="primary"
                  >{{ $t("awards.booked.cancelCoupon") }}</v-btn
                >

                <v-btn
                  v-else-if="item.status == 'Disponibile per il ritiro'"
                  block
                  large
                  @click.stop.prevent="barcodeDialog = true"
                  class="action-button elevation-0"
                  color="primary"
                >
                  Pronto per il ritiro
                  <v-dialog v-model="barcodeDialog" width="350">
                    <v-card class="w-100" style="height:300px">
                      <v-card-title>{{ item.product.name }}</v-card-title>
                      <v-card-text>
                        Usa questo codice per ritirare il premio
                      </v-card-text>

                      <barcode
                        v-bind:value="item.upc"
                        class="barcode-wrapper"
                      />
                    </v-card>
                  </v-dialog>
                </v-btn>
              </v-card-actions>
            </template>
            <template v-slot:productdescr>
              <span
                v-if="item.product && item.product.warehousePromo?.view.bubble"
                class="points-information primary--text text-uppercase font-weight-bold"
                >{{ item.product.warehousePromo?.view.bubble }}</span
              >
              <p v-if="item.product.variations" class="font-weight-bold">
                {{ item.product.variations[0]?.warehousePromo.view.bubble }}
              </p>
            </template>
            <template v-slot:headers>
              <span class="productheaders"></span>
            </template>
          </ProductCard> -->
        </v-col>
      </v-row>
    </template>
    <template v-else-if="!loading">
      <span>{{ $t("awards.history.noAwards") }}</span>
    </template>
  </div>
</template>
<style lang="scss">
.voucher-detail .title {
  display: flex;
  flex-direction: column;
}
.barcode-wrapper {
  text-align: center;
}
.barcode {
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
<script>
import AwardsCard from "./AwardsCard.vue";

import recurrentOrderService from "~/service/recurrentOrderService";
import customService from "@/service/customService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BookedAwardsHistoryList",
  components: {
    AwardsCard
  },
  props: {
    historyMode: { type: String, required: true }
  },

  data() {
    return {
      item: null,
      historyList: null,
      loading: true,
      response: null,
      barcodeDialog: false
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {
    ...mapActions({ getCart: "cart/loadCart" }),

    async fetchBookedAwards() {
      try {
        this.historyList = [];
        this.loading = true;
        let res = null;
        if (this.historyMode === "booked") {
          res = await recurrentOrderService.getAwardsOrders();
          if (res) {
            this.historyList = res.data.wishlistItems;
          }
        } else {
          // historymode === delivered
          res = await recurrentOrderService.getAwardsStory();
          if (res) {
            this.historyList = res.data.wishlistItems;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async awardsCanceled() {
      await this.fetchBookedAwards();
      await this.getCart();
    },
    async seeAward(item) {
      try {
        this.loading = true;
        let result = await customService.renderAwards(
          item.upc,
          item.product.upc,
          this.user.fidelityCard
        );
        if (result) {
          this.response = result;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async cancelAward(item) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t(
          `Sei sicuro di voler stornare il premio? <br/><span class='font-weight-bold'>${item.product.name}</span>`
        )
      });
      if (res) {
        let upcString = item.upc.toString();
        let result = await customService.cancelAwards(
          upcString,
          item.timeslotDate,
          7 // this.warehouseId when fasce pass to us
        );
        if (result) {
          // this.$emit("awardsCanceled", item.product.productId);
          this.awardsCanceled();
          this.response = result;
        }
      }
    }
  },
  mounted() {
    this.fetchBookedAwards();
  },
  watch: {
    historyMode() {
      this.fetchBookedAwards();
    }
  }
};
</script>
